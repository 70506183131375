import React, { useState, useEffect } from "react";
import Blog from "./blog";
import BlogDetail from "./blogDetail";

const Blogs = () => {
   const [blogsData, setBlogsData] = useState([]);
   const [selectedBlog, setSelectedBlog] = useState(null);

   useEffect(() => {
      const fetchedData = [
         {
            id: 1,
            // ImagePath: "blog1.svg",
            ImagePath: "blog1.jpg",
            title: "Для любителей изысканных ароматов могут подойти наш парфюмерный магазин",
            description: `Погрузитесь в мир изысканных ароматов в нашем парфюмерном магазине. Наш уютный и стильный интерьер создает идеальное окружение для знакомства с роскошными и уникальными парфюмерными композициями. От нежных цветочных нот до глубоких древесных оттенков - у нас есть аромат для каждого ценителя искусства парфюмерии.
            Наши полки увешаны лучшими мировыми брендами, предлагающими настоящее искусство в каждой капле. Вас встретит профессиональный персонал, готовый помочь вам подобрать аромат, подчеркивающий вашу уникальность и стиль. Роскошные флаконы искусно дополнят вашу коллекцию парфюмов.`,
         },
         {
            id: 2,
            ImagePath: "blog2.jpg",
            title: "Эксклюзивные коллекции для истинных ценителей, Оазис стиля и индивидуальности",
            description: `Наши полки увешаны лучшими мировыми брендами, предлагающими настоящее искусство в каждой капле. Вас встретит профессиональный персонал, готовый помочь вам подобрать аромат, подчеркивающий вашу уникальность и стиль. Роскошные флаконы искусно дополнят вашу коллекцию парфюмов.
            В нашем магазине вы обнаружите эксклюзивные коллекции, доступные только в избранных местах. Погрузитесь в уникальные ароматы, созданные для настоящих ценителей, и привнесите в свою жизнь неповторимый шарм.`,
         },
         {
            id: 3,
            ImagePath: "blog3.jpg",
            title: "Ароматная атмосфера в каждом флаконе, путеводитель в мир ароматов",
            description: `Каждый парфюм в нашем магазине - это искусно созданная история, олицетворяющая красоту, элегантность и стиль. Доверьтесь нашему магазину, чтобы обнаружить аромат, который подчеркнет вашу уникальность и сделает каждый момент особенным.
            Наши аромаконсультанты - настоящие знатоки в мире парфюмерии. Они помогут вам разгадать тайны нот, создавать гармонию аромата с вашей индивидуальностью и находить идеальный баланс между классикой и современностью.`,
         },
         {
            id: 4,
            ImagePath: "blog4.jpg",
            title: "Искусство аромата, воплощенное в каждом флаконе, приглашает вас на путешествие сквозь чувства",
            description: `В нашем магазине парфюмерии каждый флакон является уникальным произведением искусства, созданным для того, чтобы подарить вам неповторимый опыт. Мы гордимся тем, что предлагаем вам не просто ароматы, а настоящие истории, способные перенести вас в самые яркие моменты жизни. Наши консультанты с радостью помогут вам выбрать парфюм, который подчеркнет вашу индивидуальность и поможет выразить вашу эмоциональную палитру.`,
         },
         {
            id: 5,
            ImagePath: "blog5.jpg",
            title: "Мир ароматов: гармония, стиль, утонченность",
            description: `Приветствуем вас в нашем мире ароматов, где каждый запах – это искусно сочиненная симфония, призванная олицетворить красоту и элегантность. Наши аромаконсультанты, обладающие высоким профессионализмом и страстью к парфюмерии, всегда готовы помочь вам найти идеальный баланс между классическими и современными ароматами. Доверьтесь нам, чтобы открыть для себя мир нот и оттенков, который станет неотъемлемой частью вашего стиля и образа жизни.`,
         },
         {
            id: 6,
            ImagePath: "blog6.jpg",
            title: "Ароматный оазис: место, где рождаются истории",
            description: `Наш парфюмерный магазин приглашает вас окунуться в атмосферу утонченности и волшебства, где каждый флакон – это отдельная история, рассказывающая о стиле и характере. Наши аромаконсультанты обладают богатым опытом и знаниями в области парфюмерии и готовы помочь вам обнаружить тот самый аромат, который станет вашим неповторимым аккомпанементом в повседневной жизни. Доверьтесь нам, чтобы создать вокруг себя атмосферу, наполненную утонченными эмоциями и неповторимыми моментами.`,
         },
      ];

      setBlogsData(fetchedData);
   }, []);

   const openBlogDetail = (blog) => {
      setSelectedBlog(blog);
   };

   const closeBlogDetail = () => {
      setSelectedBlog(null);
   };

   return (
      <div id="blog">
         <div className="container">
            <div className="blogs-div">
               <h1 id="title">Блог</h1>
               <div className="blogs">
                  {blogsData.map((blog) => (
                     <Blog
                        key={blog.id}
                        ImagePath={blog.ImagePath}
                        title={blog.title}
                        onClick={() => openBlogDetail(blog)}
                     />
                  ))}
               </div>
            </div>
         </div>

         {selectedBlog && (
            <BlogDetail blog={selectedBlog} onClose={closeBlogDetail} />
         )}
      </div>
   );
};

export default Blogs;
