// import React, { useEffect, useState } from "react";const AboutUs = () => {//    const [hasAnimated, setHasAnimated] = useState(false);//    useEffect(() => {//       const aboutUsElement = document.getElementById("about-us");//       const aboutTextElements = document.querySelectorAll(".about-text");//       const handleScroll = () => {//          const scrollPosition = window.scrollY;
//          const elementOffset = aboutUsElement.offsetTop;

//          if (
//             !hasAnimated &&
//             scrollPosition + window.innerHeight > elementOffset
//          ) {
//             aboutTextElements.forEach((textElement, index) => {
//                textElement.classList.add("slide-in-animation");
//             });
//             setHasAnimated(true); // Set the flag to true after animating
//          }
//       };

//       window.addEventListener("scroll", handleScroll);

//       // Clean up the event listener when the component unmounts
//       return () => {
//          window.removeEventListener("scroll", handleScroll);
//       };
//    }, [hasAnimated]);

//    return (
//       <div id="about-us">
//          <div className="container">
//             <div className="about-us">
//                {/* <p className="about-text">Только</p> */}
//                <p className="about-text">Оригинальная</p>
//                <p className="about-text">парфюмерия</p>
//             </div>
//          </div>
//       </div>
//    );
// };

// export default AboutUs;

import React from "react";

const AboutUs = () => {
   return (
      <div id="about-us">
         <div className="container">
            <h1 className="about-title">
               КУПИТЬ ЭКСКЛЮЗИВНЫЕ НИШЕВЫЕ АРОМАТЫ И БЛАГОРОДНУЮ РОСКОШНУЮ
               КОСМЕТИКУ ПО ВСЕМУ МИРУ ОНЛАЙН
            </h1>
            <p>
               Parfoa® означает тщательно отобранные ароматы, роскошный
               высококачественный уход за кожей и для того, чтобы побаловать все
               ваши чувства, в конце концов, вы того стоите! - Мы намеренно
               решили предложить нашим клиентам гораздо больше, чем просто
               обычный ассортимент популярных брендов. Для вас мы ежедневно идем
               на поиски настоящих сокровищ этого мира и относимся к каждому из
               ваших заказов как к нашему самому драгоценному подарку, вы даете
               нам свое доверие, и мы обещаем дать вам волшебство... Parfoa®
               разработан, чтобы дать вам отдохнуть от беспокойной повседневной
               жизни и побаловать вас самыми ценными продуктами и изысканными,
               роскошными и уникальными нишевыми брендами. Вот почему мы
               предлагаем вам возможность купить декантированный образец из
               ассортимента нашей парфюмерии Kurfürsten в Мангейме онлайн: мы
               отправляем наши товары клиентам по всему миру.
            </p>
            <h2 className="about-title2">
               ЭКСКЛЮЗИВНЫЕ НИШЕВЫЕ АРОМАТЫ И РОСКОШНЫЕ ДУХИ
            </h2>
            <p>
               Мир ароматов разнообразен и широк - каждый аромат имеет свою
               собственную подпись и состоит из различных ценных ингредиентов,
               которые вместе создают уникальный аромат. Ароматы, которые вы
               найдете на Parfoa®, - это гораздо больше, чем просто духи; они
               ассоциируются с людьми, историями и чувствами. Если в воздухе
               есть определенный нюанс, воспоминания быстро пробуждаются. Первый
               поцелуй, дикий праздник или встреча с любимым человеком - ароматы
               могут отправить нас в эмоциональное путешествие во времени, в
               котором мы можем на мгновение забыть настоящее и всегда иметь
               счастливые воспоминания с нами. Правильный аромат - это
               произволение искусства, лекарство, которое дает нам вдохновляющее
               чувство счастья всякий раз, когда мы проносим мимо наших носов".
               Мы считаем, что уникальные люди заслуживают уникальных ароматов,
               поэтому мы предлагаем вам самые изысканные раритеты в мире. Наш
               эксклюзивный выбор ароматов состоит из благородных и роскошных
               нишевых духов, так что вы будете сильно отличаться от обычного
               мейнстрима. Как и персонаж, аромат каждого человека индивидуален
               и естественным образом подчеркивается правильными духами. Аромат
               подчеркивает вашу индивидуальность и становится вашей невидимой
               подписью, которая всегда окутывает вас, оставляя неизгладимое
               впечатление. Независимо от того, теплые ароматы, пудровые ароматы
               с доминирующим силом, пряные, свежие и цитрусовые или необычные и
               причудливые - каждая семья ароматов излучает что-то другое и
               раскрывает другой аспект нашей индивидуальности. Ароматы для
               повседневной жизни обычно немного легче и более тонкие, чем
               ароматы для вечера, которые могут быть роскошными и заманчиво
               всегда присутствующими. И поскольку различные ароматические ноты
               часто проявляются только в течение нескольких часов, мы понимаем,
               что люди любят тестировать ароматы перед покупкой.
               {/* Поэтому мы
               предлагаем вам возможность заказать декантированный образец
               примерно 3 мл духов, чтобы проверить аромат. */}
            </p>
            <h2 className="about-title2">
               РОСКОШНАЯ КОСМЕТИКА И ЭФФЕКТИВНЫЙ УХОД ЗА ЛИЦОМ И ТЕЛОМ
            </h2>
            <p>
               Уход за кожей Beauty празднуется и почитается на протяжении
               тысячелетий - потому что это то, к чему все стремятся и так
               трудно определить. Внутренняя красота часто важнее внешней, но мы
               тоже не пренебрегаем. Таким образом, наша внешность производит
               первое впечатление и отражает наше внутреннее существо. Если вы
               заботитесь о своей внешности, вы выглядите ухоженной и
               привлекательной. Вы чувствуете, что вас привлекает красивый
               человек, который наносит на вас заклинание в течение нескольких
               волшебных моментов. Но красота часто не приходит сама по себе,
               она хочет, чтобы ее постоянно воспитывали и заботили, чтобы
               украсить нас своей магнитной аурой. Чтобы сохранить красоту во
               всех ее аспектах, мы предлагаем широкий ассортимент средств по
               уходу. Утренний и вечерний уход не только важен для кожи, он
               также может оказывать успокаивающее действие на разум. Это
               означает время, которое мы удеваем только себе. Наша кожа
               покрывает наше тело и является каналом магического электричества,
               чтобы почувствовать внешний мир, из которого мы излучаем.
               Несколько минут, чтобы просто выключить и побаловать нас
               роскошными продуктами. Ритуалы, подобные этому, важны для того,
               чтобы мы не теряли из виду себя. Каждый раз, когда мы делаем
               что-то только для себя, мы заботимся о нашем общем благополучии и
               делаем себя счастливыми. В Parfoa® вы найдете множество различных
               продуктов с различными областями применения и применения -
               независимо от категорий уход за лицом, телом или волосами, мы
               предлагаем только высококачественные продукты от специальных
               брендов для вашего благополучия.
            </p>
            {/* <h2 className="about-title2">ЗАХВАТЫВАЮЩИЙ ДУХ МАКИЯЖ</h2>
            <p>
               В Parfoa® мы верим, что жизнь счастливее, когда вы чувствуете
               себя хорошо в своей собственной коже. Декоративная косметика
               является неотъемлемой частью их утренней рутины для многих людей,
               поэтому продукты должны быть простыми в использовании, веселыми и
               заманчивыми, чтобы день мог начаться хорошо. Наша декоративная
               косметика состоит из широкого ассортимента роскошных брендов,
               которые охватывают практически все, от пудры до туши и тонального
               крема. Продукты могут использоваться различными способами,
               независимо от того, направлен ли результат на естественный или
               выразительный вид. Судя по научным исследованиям, симметричное
               лицо воспринимается как красивое. Наши лица всегда немного
               асимметричны по своей природе, что можно легко сбалансировать с
               декоративной косметикой. Например, блеск для губ создает
               соблазнительный надух и вызывает немного больше объема, привлекая
               внимание другого человека своим блеском. Макияж - это то, что
               должно доставлять нам удовольствие. Мы можем использовать его,
               чтобы проявить нашу красоту и одновременно жить творчески. У
               каждого есть своя особая идея ?? красота и то, как она выглядит
               на себе. С макияжем мы приближаемся к этой идее. Соблазнительный
               взгляд, драматически и в то же время чувственный, идеально
               поставленный глубокими черными, объемными ресницами. Розовое,
               свежее лицо, которое сияет и выглядит жизненно важным благодаря
               своим молодым покрасневшим щекам, которые были выделены цветом.
               Красные губы, которые привлекают внимание к себе, без слов кричат
               о присутствии своего владельца в комнате, как только кто-то
               входит в нее. Все это не проблема с косметикой от одного из наших
               великих брендов. Parfoa® предлагает декоративную косметику от
               избранных, роскошных производителей, которая не только является
               праздником для глаз, но и красиво смотрится в вашей ванной
               комнате или сумочке. Наши роскошные продукты, такие как Xerjoff,
               оснащены упаковкой, которая была тщательно разработана, чтобы
               доставлять вам удовольствие каждый раз, когда вы смотрите на нее.
            </p> */}
         </div>
      </div>
   );
};

export default AboutUs;
